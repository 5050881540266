import Dropzone from 'dropzone';
import 'dropzone/dist/basic.css';
import 'dropzone/dist/dropzone.css';

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['output', 'zone']
  static values = { type: String }
  declare typeValue: string
  declare outputTarget: HTMLElement
  declare zoneTarget: HTMLElement
  connect() {
    const that = this
    if (this.element.dropzone) return

    this.element.dropzone = new Dropzone(this.zoneTarget, {
      url: `/time_tracking/attachments?type_id=${this.typeValue}`,
      paramName: "file",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
      },
      success(file, json) {
        that.outputTarget.innerHTML += json.template
      }
    })
    this.zoneTarget.classList.add('dropzone')
  }
}

// window.addAttachments = () => {
//   $('.js-attachment-upload').each(function () {
//     const el = $(this);
//     el.dropzone({
//       url: `/time_tracking/attachments?type_id=${el.data('type')}`,
//       paramName: "file",
//       headers: {
//         'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
//       },
//       init() {
//         this.on("success", (file, json) => {
//           el.parent().find('.js-target').append(json.template)
//         })
//       }
//     }).addClass('dropzone')
//   })
// }
