import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["title", "content"];
  static values = { privacy: String };

  connect() {
    this.defaultTitle = this.titleTarget.textContent;
    this.defaultContent = this.contentTarget.innerHTML;
    this.privacyContent = this.privacyValue;
  }

  showPrivacy() {
    this.titleTarget.textContent = "Datenschutzerklärung";
    this.contentTarget.innerHTML = this.privacyContent;
  }

  showImpressum() {
    this.titleTarget.textContent = this.defaultTitle;
    this.contentTarget.innerHTML = this.defaultContent;
  }
}
