import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"
import Highcharts from "~/utils/charts"
// import { medium } from './themes'

export default class extends Controller {
  declare chartValue: string
  static values = {
    chart: String,
  }
  isVisible = false
  options = {
    threshold: 0,
  }
  stop: Function | null = null
  connect() {
    const [_, stop] = useIntersection(this, this.options)
    this.stop = stop
  }
  appear() {
    if (this.stop)
      this.stop()
    Highcharts.chart(this.element as HTMLElement, this.chartOptions())
  }

  chartOptions() {
    const options = this.jsonChartOptions()

    return options
  }

  jsonChartOptions() {
    return JSON.parse(this.chartValue || "{}")
  }
}
