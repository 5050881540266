import Vue from 'vue';
import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';

// Drop-in fix for Highcharts issue #8477 on older Highcharts versions. The
// issue is fixed since Highcharts v6.1.1.
Highcharts.wrap(Highcharts.Axis.prototype, 'getPlotLinePath', function(proceed) {
  const path = proceed.apply(this, Array.prototype.slice.call(arguments, 1));
  if (path) {
    path.flat = false;
  }
  return path;
});

import highchartsMore from 'highcharts/highcharts-more';
// import solidGauge from 'highcharts/modules/solid-gauge';
highchartsMore(Highcharts);
// solidGauge(Highcharts);
Vue.use(VueHighcharts, { Highcharts });

export default Highcharts;
