import { Application } from "@hotwired/stimulus"

const Stimulus = Application.start()

import ChartController from './controllers/chart_controller'
import SubmitOnChangeController from './controllers/submit_on_change_controller'
import DropzoneController from './controllers/dropzone_controller'
import ImpressumModalController from './controllers/impressum_modal_controller'
Stimulus.register('chart', ChartController)
Stimulus.register('submit-on-change', SubmitOnChangeController)
Stimulus.register('dropzone', DropzoneController)
Stimulus.register('impressum-modal', ImpressumModalController)

