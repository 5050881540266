import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
  connect() {
    const form = this.element.closest("form")
    this.element.addEventListener("change", () => {
      console.log('change')
      form?.submit()
    })
    this.element.addEventListener("input", () => {
      console.log('input')
      form?.submit()
    })
    $(this.element).on('change', () => {
      console.log('change jquery')
      form?.submit()
    })
  }
}
